





























import { Vue, Component } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';

@Component({ name: 'KuspitCongratulations' })
export default class KuspitCongratulations extends Vue {
  i18n_namespace = 'components.contract-savings.congratulations';

  small_screen: null | boolean = null;

  nextStep() {
    this.$emit('nextStep');
  }

  mounted() {
    this.small_screen = getScreenSizeVariant() === 'small';
  }
}

